/* src/App.css */
body {
  font-family: "Helvetica Neue", sans-serif; /* Change the font family */
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 450px;
  text-align: center;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  width: 100%;
  background-color: #1b7571;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #155d58;
}

.certificate-details {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.error-message {
  font-size: 18px;
  margin-bottom: 20px;
  color: #e74c3c;
}

.renew-button {
  margin-top: 20px;
}

.renew-button button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.renew-button button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
}
